import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuToggle,
  Spinner,
  useDisclosure
} from "@nextui-org/react";
import Lottie from "lottie-react";
import React from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import underConstructionAnimation from "src/assets/lottie-under-construction.json";
import { logoutUser } from "src/utils/api";
import useMe from "src/utils/useMe";
import ThemeSwitcher from "./ThemeSwitcher";
import gsLogo from "/gs-logo.svg";

export default function MultiPurposeMapLayout() {
  const navigation = useNavigation();

  return (
    <div className="h-full w-full bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500">
      {navigation.state === "loading" ? (
        <div className="flex h-full w-full items-center justify-center bg-gray-200/75 dark:bg-gray-900/90">
          <Spinner size="lg" color="secondary" labelColor="secondary" />
        </div>
      ) : (
        <div className="h-full w-full bg-gray-200/75 dark:bg-gray-900/90">
          <SarsNavbar />
          <Outlet />
        </div>
      )}
    </div>
  );
}

const MapDropdown = () => {
  const { pathname } = useLocation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  let currentMapName = "Multi Purpose Map";
  if (pathname === "/map/1") {
    currentMapName = "Regulation Map";
  } else if (pathname === "/map/2") {
    currentMapName = "Project Map";
  }

  return (
    <>
      <Dropdown>
        <DropdownTrigger className="hidden sm:flex">
          <Button
            variant="flat"
            endContent={
              <span className="iconify mdi--chevron-down w-6 h-6 ml-2"></span>
            }
          >
            {currentMapName}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          variant="faded"
          aria-label="Dropdown menu with description"
          disabledKeys={[pathname]}
        >
          <DropdownSection title="Maps" showDivider>
            <DropdownItem
              key="/map/1"
              href="/map/1"
              description="Regulation Tracker"
              startContent={
                <span className="iconify mdi--map w-6 h-6 mr-2"></span>
              }
            >
              Regulation Map
            </DropdownItem>
            <DropdownItem
              key="/map/2"
              href="/map/2"
              description="Project Tracker"
              startContent={
                <span className="iconify mdi--map w-6 h-6 mr-2"></span>
              }
            >
              Project Map
            </DropdownItem>
          </DropdownSection>
          <DropdownSection>
            <DropdownItem
              key="add"
              color="success"
              description="Create a new map"
              startContent={
                <span className="iconify mdi--plus w-6 h-6 mr-2"></span>
              }
              onClick={onOpen}
            >
              Add
            </DropdownItem>
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
      <Modal size="4xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Create new map
              </ModalHeader>
              <ModalBody>
                <Lottie
                  className="mx-auto w-full max-w-[500px]"
                  animationData={underConstructionAnimation}
                />
                <p className="text-3xl text-center opacity-50">Coming soon</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="secondary" onPress={onClose}>
                  Done
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

const SarsNavbar = () => {
  const { me } = useMe();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleLogout = () => {
    logoutUser();
    navigate("/login");
  };

  return (
    <Navbar onMenuOpenChange={setIsMenuOpen} maxWidth="xl">
      <NavbarContent className="max-w-10">
        <NavbarBrand>
          <div className="w-10 h-10 flex justify-center items-center bg-black/25 dark:bg-transparent rounded-2xl">
            <img
              src={gsLogo}
              className="logo max-w-8 opacity-75 cursor-pointer hover:opacity-100 transition-opacity"
              alt="Gold Standards logo"
              onClick={() => navigate("/map")}
            />
          </div>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="flex-1 hidden sm:flex gap-4" justify="start">
        <NavbarItem isActive={pathname.startsWith("/map")}>
          <Link
            color={pathname.startsWith("/map") ? "secondary" : "foreground"}
            href="/map"
            className="text-sm px-2 py-4 uppercase font-semibold"
          >
            Demo
          </Link>
        </NavbarItem>
        <NavbarItem isActive={pathname === "/proposal"}>
          <Link
            href="/proposal"
            color={pathname === "/proposal" ? "secondary" : "foreground"}
            className="text-sm px-2 py-4 uppercase font-semibold"
          >
            Proposal
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent as="div" justify="end">
        {pathname.startsWith("/map") && <MapDropdown />}
        <ThemeSwitcher />
        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <Avatar
              isBordered
              as="button"
              className="transition-transform p-0"
              color="secondary"
              size="sm"
              src="/avatar-placeholder.gif"
            />
          </DropdownTrigger>
          <DropdownMenu aria-label="Profile Actions" variant="flat">
            <DropdownItem
              key="profile"
              className="h-14 py-3 gap-3"
              textValue={`Signed in as ${me?.email}`}
            >
              <p className="">Signed in as</p>
              <p className="font-semibold">{me?.email}</p>
            </DropdownItem>
            <DropdownItem
              key="logout"
              color="danger"
              textValue="Log Out"
              onClick={handleLogout}
            >
              <div className="flex items-center h-8">
                <span className="iconify mdi--logout w-6 h-6 mr-2"></span>
                <span>Log Out</span>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
      </NavbarContent>

      <NavbarMenu>
        <Link
          color="foreground"
          className="w-full py-2"
          href="/map/1"
          size="lg"
        >
          Regulation Map
        </Link>
        <Link
          color="foreground"
          className="w-full py-2"
          href="/map/2"
          size="lg"
        >
          Project Map
        </Link>
        <Divider />
        <Link
          color="foreground"
          className="w-full py-2"
          href="/proposal"
          size="lg"
        >
          Proposal
        </Link>
        <Divider />
        <Link
          color="danger"
          className="w-full"
          href="#"
          size="lg"
          onClick={handleLogout}
        >
          Log Out
        </Link>
      </NavbarMenu>
    </Navbar>
  );
};
