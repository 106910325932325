import axios from "axios";
import mockProjects from "src/pages/ProjectMap/mockProjects";

// const API_URL = "http://localhost/api"; // Replace with your API URL
const API_URL = "/api"; // Replace with your API URL

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

function setCookie(name: string, value: string, days: number) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=/";
}

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
}

function deleteCookie(name: string) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
}

axios.interceptors.request.use(
  (config) => {
    const token = getCookie("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  await sleep(500);
  const response = await axios.post(`${API_URL}/login`, { email, password });

  setCookie("token", response.data.access_token, 30);
  return response.data;
};

export const getMe = async () => {
  const response = await axios.get(`${API_URL}/me`);
  return response.data;
};

export const getRegulations = async () => {
  const response = await axios.get(`${API_URL}/regulations`);
  return response.data;
};

export const getProjects = async () => {
  // const response = await axios.get(`${API_URL}/regulations`);
  return mockProjects;
  // return response.data;
};

export const logoutUser = async () => {
  // await axios.post(`${API_URL}/logout`); // Optional: Call server-side logout endpoint
  deleteCookie("token");
};
