import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Input,
  Link,
  Tooltip,
} from "@nextui-org/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import Lottie from "lottie-react";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import successAnimation from "src/assets/lottie-success.json";
import { EyeFilledIcon, EyeSlashFilledIcon } from "src/components/icons";
import { loginUser } from "src/utils/api";
import { useToggle } from "usehooks-ts";
import gsLogo from "/gs-logo.svg";

const inputClassNames = {
  input: [
    "bg-transparent",
    "text-black/90 dark:text-white/90",
    "placeholder:text-default-700/50 dark:placeholder:text-white/60",
  ],
  innerWrapper: "bg-transparent",
  inputWrapper: [
    "bg-default-200/50",
    "dark:bg-default/40",
    "hover:bg-default-200/70",
    "dark:hover:bg-default/50",
    "group-data-[focus=true]:bg-default-200/50",
    "dark:group-data-[focus=true]:bg-default/50",
  ],
};

interface LoginFormValues {
  email: string;
  password: string;
}
export default function Login() {
  const navigate = useNavigate();
  const [isPasswordVisible, togglePasswordVisibility] = useToggle();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({ values: { email: "", password: "" } });

  const mutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      console.log("Login successful", data);
    },
    onError: (error: AxiosError<{ detail: string }>) => {
      console.error("Login failed", error);
      if (error.response && error.response.status > 400) {
        setErrorMessage(
          error.response?.data?.detail ||
            "An error occurred during login. We are working on it."
        );
      } else {
        setErrorMessage("Something went wrong. We are working on it.");
      }
    },
  });
  const onSubmit: SubmitHandler<LoginFormValues> = (data: LoginFormValues) => {
    console.log("LoginFormValues", data);
    setErrorMessage(null);
    mutation.mutate(data);
  };

  return (
    <div className="flex h-full overflow-y-scroll w-screen items-center justify-center bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500 p-2 sm:p-4 lg:p-8">
      <Card
        isBlurred
        className="border-none bg-background/60 dark:bg-default-100/70 max-w-[520px] w-[420px] min-w-[320px]"
        shadow="sm"
      >
        <CardBody className="px-4 sm:px-8 pt-6 pb-10">
          <p className="pb-8 text-xl font-normal">Sign In</p>
          <div className="relative flex justify-center min-h-42 h-56">
            <img
              src={gsLogo}
              className="absolute logo max-w-40 opacity-75"
              alt="Gold Standards logo"
            />
            {mutation.isSuccess && (
              <div className="absolute max-w-56 bottom-10">
                <Lottie
                  animationData={successAnimation}
                  loop={false}
                  onComplete={() => {
                    navigate("/");
                  }}
                />
              </div>
            )}
          </div>
          <form
            className="flex flex-col gap-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <Input
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  required
                  autoFocus
                  autoComplete="on"
                  classNames={inputClassNames}
                  isDisabled={mutation.isPending || mutation.isSuccess}
                  isInvalid={Boolean(errors.email)}
                  errorMessage={String(errors.email?.message) || ""}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              render={({ field }) => (
                <Input
                  label="Password"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter your password"
                  required
                  autoComplete="current-password"
                  classNames={inputClassNames}
                  endContent={
                    <span
                      className="w-7 h-7 cursor-pointer"
                      onClick={togglePasswordVisibility}
                      aria-label="toggle password visibility"
                    >
                      {isPasswordVisible ? (
                        <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      ) : (
                        <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      )}
                    </span>
                  }
                  isDisabled={mutation.isPending || mutation.isSuccess}
                  isInvalid={Boolean(errors.password || errorMessage)}
                  errorMessage={
                    String(errors.password?.message || errorMessage) || ""
                  }
                  {...field}
                />
              )}
            />

            <div className="flex justify-between my-3">
              <Checkbox color="secondary" defaultSelected>
                <span className="text-sm opacity-50">Remember Me</span>
              </Checkbox>
              <Tooltip content="Coming Soon" showArrow>
                <Link
                  color="foreground"
                  href="#"
                  className="text-sm opacity-50 font-normal	"
                >
                  Forgot Password?
                </Link>
              </Tooltip>
            </div>
            <Button
              type="submit"
              size="lg"
              className="h-14 bg-foreground/10 dark:bg-foreground/20 mt-4"
              isDisabled={mutation.isPending || mutation.isSuccess}
              isLoading={mutation.isPending}
            >
              Sign In
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}
