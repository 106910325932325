import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { getMe } from "src/utils/api";
import { tracker } from "src/main";

interface User {
  id: number;
  email: string;
}
const useMe = () => {
  const query = useQuery<User>({ queryKey: ["me"], queryFn: getMe });
  const me = query.data;

  useEffect(() => {
    if (me?.id) {
      Sentry.setUser({
        id: me.id,
        email: me.email
      });
      tracker.setUserAnonymousID(String(me.id));
      tracker.setUserID(me.email);
      // @ts-expect-error hotjar
      window.hj('identify', me.id, {
        email: me.email
      });
    }
  }, [me?.id, me?.email])

  return { ...query, me };
};

export default useMe;
